@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css?family=Italiana&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css?family=Birthstone+Bounce:wght@0,400;0,500&display=swap');
@import './grid-gallery.min.css';

@layer components {
    * {
        @apply m-0 box-border;
    }

    html {
        @apply scroll-smooth;
    }

    body {
        @apply font-['Poppins'] bg-accent-4 flex flex-col items-center justify-center h-full m-0;
    }

    input:disabled + label {
        @apply opacity-40;
    }

    select {
        @apply indent-px text-clip appearance-none text-center;
    }

    main {
        @apply pt-[50px] md:pt-0 md:mt-0 flex flex-col md:flex-row h-[calc(100vh-58px)] md:h-full w-screen -m-[1px];
    }

    #loader {
        @apply absolute left-0 right-0 top-0 bottom-0 h-full w-full bg-accent-4 z-[100] flex flex-col text-white
        justify-center items-center;

        lottie-player {
            @apply w-24 h-24 md:w-40 md:h-40;
        }
    }

    [id^=page-] {
        @apply bg-black/80 absolute z-[50] md:mt-[70px] left-0 top-0 bottom-0 right-0 transition-all duration-700;

        .wrapper {
            @apply transition-all overflow-y-auto h-full duration-700
                appearance-none absolute w-full text-sm md:text-base left-1/2 -translate-x-1/2
                -translate-y-1/2 top-1/2 px-6 md:px-12 pt-32 pb-12 md:pt-20 bg-accent-3 text-accent-1;

            .close-page {
                @apply border mt-16 md:mt-4 border-accent-1 hover:bg-accent-1 hover:text-accent-3 transition-all duration-500 p-2 absolute top-0 right-[25px] ml-[50px];

                svg {
                    @apply w-6 h-6 text-accent-1;
                }
            }
        }
    }

    nav {
        @apply z-[100] opacity-0 w-screen h-screen fixed top-0 left-0 bg-black/50 transition-all duration-500;

        > div {
            @apply relative h-full w-0 bg-accent-3 overflow-x-hidden pt-[60px] transition-all duration-500;

            .footer {
                @apply py-2 px-8 text-accent-1 text-xs mt-8 text-center md:text-left;
            }
        }

        button {
            @apply text-center md:text-left w-full py-2 px-8 appearance-none no-underline text-accent-1 block transition-all duration-300 hover:text-white;

            &.accordion-header {
                @apply flex justify-center md:justify-between relative;

                &.active {
                    &:after {
                        @apply transition-all duration-700 rotate-0;
                    }
                }

                &:after {
                    @apply transition-all duration-700 -rotate-90 w-8 h-8 absolute right-6 self-center
                    content-[url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9Im5vbmUiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZT0iI0E0OEQ3MiI+CiAgPHBhdGggc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBkPSJNMTkuNSA4LjI1bC03LjUgNy41LTcuNS03LjUiIC8+Cjwvc3ZnPg==)];
                }

                &:hover {
                    &:after {
                        @apply brightness-200;
                    }
                }
            }
        }

        button.close-sidebar:not(.open-page) {
            @apply absolute w-auto p-0 top-[25px] right-[25px] ml-[50px] #{!important};

            svg {
                @apply w-8 h-8 text-accent-1;
            }
        }

        .social-group {
            @apply justify-center flex gap-4 md:justify-start mt-12 md:ml-8;

            a {
                @apply flex items-center;

                svg {
                    @apply w-8 h-8 md:w-6 md:h-6 fill-accent-1 hover:fill-white;
                }
            }
        }
    }

    #welcome {
        @apply bg-black/80 fixed z-[70] left-0 top-0 bottom-0 right-0 transition-all;

        .wrapper {
            @apply transition-all duration-700 outline outline-accent-3 outline-offset-4 appearance-none shadow absolute
            w-[calc(100%-50px)] md:w-1/3 text-center text-sm md:text-base left-1/2 -translate-x-1/2 -translate-y-1/2 top-1/2
            p-6 bg-accent-3 text-accent-1;

            svg {
                @apply w-24 h-24 mx-auto fill-white;
            }

            div {
                @apply font-['Birthstone_Bounce'] text-6xl text-white mb-6;
            }

            h1 {
                @apply leading-tight text-accent-1;
            }

            button {
                @apply border border-accent-1 hover:bg-accent-1 hover:text-accent-3 transition-all duration-500 px-4 py-2 mt-4;
            }
        }
    }

    header {
        @apply flex items-center justify-between md:justify-center gap-3 md:gap-4 absolute left-0 right-0 top-0 w-full h-[45px]
        md:h-[70px] z-[60];

        .desktop {
            form {
                @apply flex flex-col md:flex-row;
            }
        }

        .mobile {
            form {
                @apply flex flex-col;
            }
        }

        .subheader {
            @apply bg-accent-1 absolute left-0 right-0 top-[45px] md:top-[70px] z-[60] h-[3px] shadow;
        }

        .menu-group {
            @apply md:flex divide-x hidden;

            .input-group {
                @apply flex flex-col text-center cursor-pointer w-full text-white;

                > label {
                    @apply px-4 whitespace-nowrap;
                }

                label {
                    @apply text-xs text-accent-1;
                }

                div:not(.dropdown) {
                    @apply flex mx-auto mt-1;

                    select {
                        @apply text-base leading-[1.2em] pt-0 ml-6 mr-0 bg-transparent outline-none tracking-wider;
                    }

                    input {
                        @apply text-base mx-auto leading-none pt-0 w-[120px] pb-0 ml-4 text-center bg-transparent
                        tracking-wider outline-none;
                    }

                    svg {
                        @apply inline w-4 h-4 mr-6 self-center;
                    }
                }
            }
        }

        .button-group {
            @apply flex items-center text-center cursor-pointer font-bold font-['Italiana'] text-sm tracking-widest text-white;

            a, button {
                @apply transition-all duration-300 hover:bg-white hover:text-[#292729] px-3 md:px-4 py-2 md:py-3 leading-none
                whitespace-nowrap border-[0.5px] border-white uppercase tracking-widest;
            }

            button[type=submit],
            a {
                @apply hidden md:block mr-0 md:mr-2;
            }

            button[type=button] {
                @apply ml-1 md:ml-0 block md:hidden;
            }
        }

        .lang-group {
            @apply flex divide-x text-white;

            a {
                @apply block px-2 text-xs md:text-base;
            }
        }

        .mobile {
            @apply bg-accent-4 h-0 w-full flex flex-col fixed z-[100] left-0 top-0 overflow-y-hidden duration-500;

            .close-booking {
                @apply text-accent-1/50 text-lg mt-4;
            }
        }

        .desktop {
            @apply flex gap-2 items-center;

            button svg {
                @apply w-5 h-5 md:w-6 md:h-6 fill-white;
            }
        }

        .mobile-content {
            @apply relative top-[25%] w-full text-center -mt-[30px];

            .menu-group {
                @apply flex divide-x-0 flex-col gap-4 #{!important};
            }

            .button-group {
                @apply mt-8 justify-center;

                button[type=button] {
                    @apply hidden #{!important};
                }

                button[type=submit],
                a {
                    @apply text-lg px-8 py-3 block #{!important};
                }
            }
        }
    }

    section {
        @apply overflow-hidden bg-center bg-cover bg-no-repeat w-screen h-auto md:w-auto md:h-[calc(100vh-70px)] bg-accent-4
        cursor-pointer flex-[0.65] relative transition-all duration-700 md:mt-[70px];

        &.active {
            @apply flex-[5];

            .toggle-overlay {
                @apply block transition-all duration-300 md:opacity-0 md:hover:opacity-[100] absolute top-[50%] left-0
                md:top-0 md:bottom-0 md:left-0 md:right-0 z-[25];

                button[type=button] {
                    @apply whitespace-nowrap shadow md:absolute md:left-1/2 md:top-1/2 md:-translate-x-1/2 md:-translate-y-1/2
                    cursor-pointer md:outline md:outline-2 md:outline-offset-4 pl-3 pr-1 py-1 md:px-4
                    md:py-2 text-white text-base md:text-2xl transition-all
                    duration-300 md:hover:scale-[1.2] flex items-center gap-2;

                    svg {
                        @apply w-4 h-4 inline md:hidden;
                    }
                }
            }

            .toggle-content {
                @apply opacity-100 transition-all duration-700 h-0 flex flex-col w-full absolute overflow-y-scroll z-50;

                .open-booking {
                    @apply my-4 block md:hidden border border-white px-4 py-2 uppercase text-lg font-['Italiana']
                    tracking-widest;
                }

                [id^=close-modal] {
                    @apply z-50 absolute top-4 right-4 md:top-8 md:right-8 w-8 h-8 text-white;
                }

                &.active {
                    @apply pb-0 md:pb-6;
                }

                p {
                    @apply tracking-wider mb-4 font-thin;
                }

                .direction {
                    @apply flex flex-col md:flex-row gap-2;

                    .applemap {
                        @apply hover:scale-[0.95] transition-all duration-500 flex gap-2 justify-start items-center md:text-left
                        p-3 rounded-2xl font-sans font-[300] leading-[24px] text-[#1d87ff] text-lg bg-[#f7f7f7] border
                        border-[#f7f7f7] whitespace-nowrap;

                        svg {
                            @apply w-12 h-12;
                        }
                    }

                    .waze {
                        @apply hover:scale-[0.95] transition-all duration-500 flex gap-4 justify-start items-center md:text-left
                        p-4 rounded-2xl font-sans font-[700] leading-none text-slate-900 text-lg bg-[#30c2f1]
                        whitespace-nowrap;

                        svg {
                            @apply w-11 h-11;
                        }
                    }

                    .googlemap {
                        @apply hover:scale-[0.95] transition-all duration-500 flex gap-3 md:gap-2 justify-start items-center
                        md:text-left py-4 pl-5 pr-4 md:p-4 rounded-2xl font-sans font-[600] leading-none text-[#656566]
                        text-lg bg-white border border-slate-200 whitespace-nowrap;

                        svg {
                            @apply w-10 h-10;
                        }
                    }
                }

                .description {
                    @apply text-white p-4 md:p-8;

                    h4.villa {
                        @apply whitespace-normal md:whitespace-nowrap font-['Italiana'] text-white font-bold
                        tracking-tight text-3xl md:text-5xl;
                    }

                    span.address {
                        @apply block mb-4 whitespace-normal md:whitespace-nowrap text-white/50
                        tracking-wider text-sm md:text-xl;
                    }

                    hr {
                        @apply w-1/4 mb-4;
                    }
                }

                h4.accordion-header {
                    @apply flex justify-between cursor-pointer w-full p-4 text-white font-bold font-['Italiana'] text-3xl
                    md:p-8 md:text-5xl;

                    &.active {
                        &:after {
                            @apply transition-all duration-700 rotate-0;
                        }
                    }

                    &:after {
                        @apply transition-all duration-700 -rotate-90 brightness-[200]
                        content-[url(data:image/svg+xml;base64,PHN2ZyB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiBmaWxsPSJub25lIiB2aWV3Qm94PSIwIDAgMjQgMjQiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2U9ImN1cnJlbnRDb2xvciIgYXJpYS1oaWRkZW49InRydWUiIGNsYXNzPSJ3LTYgaC02IiB3aWR0aD0iMjQiICBoZWlnaHQ9IjI0IiA+CiAgPHBhdGggc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBkPSJNMTkuNSA4LjI1bC03LjUgNy41LTcuNS03LjUiIHN0cm9rZT0iIzBGMTcyQSIgZmlsbD0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxLjVweCI+PC9wYXRoPgo8L3N2Zz4=)];
                    }
                }

                div.accordion-content {
                    @apply relative px-4 md:px-8 transition-all duration-700 text-white max-h-0 overflow-y-hidden
                    overflow-x-scroll;

                    .map {
                        @apply my-4;
                    }

                    .spacer {
                        @apply py-4;
                    }

                    &.contact-us {
                        @apply text-center;

                        svg {
                            @apply mx-auto w-24 md:w-48 h-24 md:h-48 fill-white;
                        }

                        h4 {
                            @apply font-bold font-['Italiana'] text-2xl text-white/60;
                        }
                    }
                }

                & > div {
                    @apply w-full block;

                    &:nth-child(2) {
                        @apply backdrop-brightness-[50%];
                    }
                    &:nth-child(3) {
                        @apply backdrop-brightness-[60%];
                    }
                    &:nth-child(4) {
                        @apply backdrop-brightness-[70%];
                    }
                    &:nth-child(5) {
                        @apply backdrop-brightness-[80%];
                    }
                    &:nth-child(6) {
                        @apply backdrop-brightness-[90%];
                    }
                    &:nth-child(7) {
                        @apply backdrop-brightness-[100%];
                    }
                }
            }

            img.cover {
                @apply block md:overflow-hidden transition-all duration-300 opacity-100 #{!important};
            }

            .info {
                @apply opacity-100 transition-all duration-300;
            }

            .strip {
                @apply opacity-0 transition-all duration-300 scale-[1.5];
            }

            .strip-bg {
                @apply opacity-0 bg-transparent backdrop-grayscale-0 backdrop-blur-none transition-all duration-300;
            }
        }

        .toggle-overlay {
            @apply hidden;
        }

        .toggle-content {
            @apply opacity-0 h-0 hidden transition-all duration-700;
        }

        img.cover {
            @apply w-full h-full md:w-full md:h-screen object-contain md:object-cover absolute left-0 bottom-0 z-10
            transition-all duration-300 animate-[slide-move_100s_linear_infinite] md:animate-[move_80s_linear_infinite]
            opacity-0 #{!important};
        }

        .info {
            @apply z-40  m-0 opacity-0 absolute bottom-[20px] md:bottom-[80px] left-0;

            .description {
                @apply flex flex-col items-start justify-center py-1 md:py-4 pl-4 pr-4 md:pr-10 bg-gradient-to-r from-black/80
                via-black/80 to-transparent relative w-full;

                h3 {
                    @apply font-['Italiana'] text-2xl md:text-6xl font-bold tracking-tight text-white leading-none;
                }

                span {
                    @apply text-xs md:text-base text-slate-300 leading-tight;
                }
            }

            .info-group {
                @apply flex relative md:whitespace-nowrap;

                .logo-wrapper {
                    @apply flex w-[80px] p-[10px] md:w-[200px] relative md:p-[20px];

                    svg {
                        @apply w-[60px] md:w-[160px] mx-auto fill-white;
                    }
                }
            }
        }

        .strip {
            @apply cursor-pointer flex flex-col justify-center h-full md:h-auto whitespace-nowrap origin-top-left
            md:-rotate-90 left-[10px] md:left-[30px] scale-100 z-20 opacity-100 transition-all duration-300 transform
            absolute md:-bottom-[20px] text-white tracking-tight py-2;

            h4 {
                @apply whitespace-normal md:whitespace-nowrap font-['Italiana'] leading-none text-white font-bold
                tracking-tight text-2xl md:text-5xl;
            }

            span {
                @apply whitespace-normal md:whitespace-nowrap leading-tight text-white
                tracking-wider text-xs md:text-xl;
            }
        }

        .strip-bg {
            @apply backdrop-grayscale w-full h-full backdrop-blur transition-all duration-300 z-10;

            .overlay {
                @apply absolute top-0 md:top-0 bottom-0 md:bottom-0 left-0 right-0  bg-gradient-to-r md:bg-gradient-to-t
                via-70% md:via-50% to-transparent z-50;
            }

            .hori {
                @apply max-h-[100px] h-full float-right grayscale block md:hidden;

                &.logo {
                    @apply p-2 fill-white;
                }
            }

            .vert {
                @apply h-full hidden grayscale md:block;

                &.logo {
                    @apply h-auto p-4 fill-white #{!important};
                }
            }
        }
    }


    .booking {
        .title-wrapper {
            @apply text-center mb-4 md:-mt-12;

            h3 {
                @apply text-5xl font-['Italiana'] text-accent-1;
            }

            span {
                @apply text-sm text-accent-1/50;
            }
        }

        .form-container {
            @apply flex flex-col md:flex-row max-w-4xl mx-auto w-full bg-accent-3 text-accent-1 outline outline-accent-3
            outline-offset-4 appearance-none shadow;

            .cover-wrapper {
                @apply hidden md:block relative w-full md:w-1/2 overflow-hidden;

                .info {
                    @apply z-40  m-0 absolute bottom-[20px] md:bottom-[40px] left-0;

                    .info-group {
                        @apply flex relative md:whitespace-nowrap;

                        .logo-wrapper {
                            @apply flex w-[40px] p-[5px] md:w-[80px] relative md:p-[10px] bg-accent-1;

                            svg {
                                @apply w-[40px] md:w-[80px] mx-auto fill-white;
                            }
                        }
                    }
                }

                img.cover {
                    @apply w-full h-full object-contain md:object-cover absolute left-0 bottom-0 z-10
                    transition-all duration-300 animate-[slide-move_100s_linear_infinite]
                    md:animate-[move_80s_linear_infinite] #{!important};
                }
            }

            .form-wrapper {
                @apply w-full md:w-1/2;

                form {
                    @apply p-4 flex flex-col w-full gap-4;

                    .form-group {
                        @apply flex flex-col gap-2 w-full;

                        input {
                            @apply text-white py-2 px-2 bg-black/10 border-b-2 border-accent-1 appearance-none
                            ring-0 outline-none;
                        }

                        .info-group {
                            @apply flex-col text-center py-4 bg-accent-4/20;

                            div:nth-child(1) {
                                @apply text-sm leading-none;
                            }

                            div:nth-child(2) {
                                @apply text-3xl md:text-5xl font-['Italiana'];
                            }

                            div:nth-child(3) {
                                @apply text-sm;
                            }

                            div:nth-child(4) {
                                @apply text-xs;
                            }
                        }

                        .gateway-wrapper {
                            @apply flex flex-col md:flex-row gap-4 p-2;

                            label {
                                @apply object-contain object-center flex items-center cursor-pointer w-full md:w-1/2 transition-all duration-500 text-center bg-white rounded-lg;

                                img {
                                    @apply max-w-full max-h-[60px] w-auto mx-auto overflow-hidden px-4 py-2;
                                }
                            }
                        }

                        .coupon {
                            @apply flex gap-2;

                            input {
                                @apply w-1/2;
                            }

                            button {
                                @apply px-4 py-2 border transition-all duration-500 border-accent-1 bg-accent-1
                                text-accent-3 hover:bg-accent-3 hover:text-accent-1;
                            }
                        }

                        .info {
                            @apply text-[10px];
                        }

                        label {
                            @apply text-xs uppercase;
                        }

                        .amount {
                            @apply text-3xl leading-none;

                            small {
                                @apply text-accent-1/50 mr-1;
                            }
                        }
                    }

                    .form-footer {
                        @apply flex items-center gap-4 justify-start md:justify-end md:pt-8;

                        .countdown-group {
                            @apply gap-2 hidden md:flex;

                            svg {
                                @apply w-6 h-6;
                            }


                        }

                        .button-group {
                            @apply fixed justify-center left-0 right-0 bottom-0 md:relative;

                            .countdown-group {
                                @apply flex md:hidden gap-2 justify-center text-xs bg-accent-3 py-2;

                                svg {
                                    @apply w-4 h-4;
                                }
                            }

                            button {
                                @apply w-full md:w-auto justify-center transition-all duration-500 border border-accent-1
                                bg-accent-1 text-accent-3 hover:bg-accent-3 hover:text-accent-1 py-5 md:py-3 px-2
                                flex gap-2 items-center;

                                svg {
                                    @apply inline w-6 h-6;
                                }
                            }
                        }
                    }
                }
            }
        }

        :checked + label {
            @apply outline-accent-1 outline outline-2 outline-offset-4;
        }
    }

    footer{
        @apply justify-center flex gap-2 items-center bg-accent-4 text-accent-5 w-full text-xs md:text-sm py-2
        md:py-1 text-center md:-mt-6 z-50;

        svg:last-child {
            @apply w-5 transform -scale-x-100 fill-accent-5;
        }

        svg:first-child {
            @apply w-5 fill-accent-5;
        }
    }

    /* SweetAlert2 */

    .swal2-container {

        .swal2-icon {

            &.swal2-error,
            &.swal2-success,
            &.swal2-info,
            &.swal2-warning {
                @apply border-accent-1 text-accent-1 #{!important};

                [class^=swal2-x-mark-line],
                [class^=swal2-success-line] {
                    @apply bg-accent-1 #{!important};
                }

                .swal2-success-ring {
                    @apply border-accent-1 #{!important};
                }
            }
        }

        h2.swal2-title {
            @apply text-white font-['Italiana'] font-bold text-4xl #{!important};
        }

        .swal2-html-container {
            @apply text-accent-1 text-center mb-4 text-sm #{!important};
        }

        .swal2-popup {
            @apply bg-accent-3 rounded-none outline outline-accent-3 outline-offset-4 shadow #{!important};
        }

        button.swal2-styled {
            @apply transition-all duration-500 hover:bg-accent-1 hover:text-accent-3 bg-accent-3 border border-accent-1 border-solid text-accent-1 rounded-none outline-none ring-0 #{!important};
        }
    }

    /* MCDatepicker */

    .mc-calendar {
        @apply font-['Poppins'] rounded-none #{!important};

        &.mc-calendar--modal {
            @apply bg-accent-1 #{!important};

            &.mc-calendar--opened {
                @apply top-auto md:top-[50vh] bottom-0 md:bottom-auto animate-[stretch_.3s_ease]
                md:animate-[zoom-in_.3s_ease] #{!important};
            }

            .mc-date--active,
            .mc-date--active.mc-date--today,
            .mc-month-year__cell {
                @apply text-white #{!important};
            }

            .mc-picker__header,
            .mc-select__nav,
            .mc-btn--success,
            .mc-table__weekday {
                @apply text-accent-1 #{!important};
            }

            .mc-date--inactive,
            .mc-month-year__cell.mc-month-year__cell--inactive,
            .mc-date--inactive.mc-date--picked {
                @apply text-accent-2 #{!important};
            }

            .mc-date--active.mc-date--picked,
            .mc-month-year__cell--picked,
            .mc-display {
                @apply bg-accent-1 #{!important};
            }

            .mc-picker,
            .mc-month-year__preview,
            .mc-date--active,
            .mc-date--active.mc-date--today {
                @apply bg-accent-4 #{!important};
            }

            .mc-calendar--modal,
            .mc-date,
            .mc-month-year__cell,
            .mc-picker {
                @apply rounded-none #{!important};
            }
        }
    }

    /* Dropdown */

    .dropdown {
        @apply relative;

        label {
            @apply whitespace-nowrap indent-px text-base text-white text-clip appearance-none text-center pt-0 ml-6 mr-2
            bg-transparent outline-none tracking-wider cursor-pointer #{!important};

            span {
                @apply leading-none;
            }
        }

        ul {
            @apply max-h-[48vh] overflow-y-scroll whitespace-nowrap z-[100] shadow-[0_0_30px_rgba(0,0,0,0.3)] text-base
            transition-all duration-300 translate-y-[1em] absolute top-[2.5em] appearance-none invisible opacity-0
            left-[calc(50%+1rem)] -translate-x-1/2;

            li {
                @apply bg-accent-4 px-6 py-3 text-white;

                &:not(:first-child) {
                    @apply border-t border-accent-2;
                }

                &:hover {
                    @apply bg-accent-1;
                }

                &[disabled] {
                    @apply bg-accent-2 text-slate-200/50 leading-none;
                }
            }
        }

        input[type="checkbox"],
        input[type="hidden"] {
            @apply hidden;
        }

        input[type="checkbox"]:checked ~ ul {
            @apply visible transition-all duration-300 translate-y-0 opacity-100;
        }
    }

    /* Calendar */


    .calendar {

        .reference {
            @apply my-4;

            .weekday {
                @apply inline-block w-6 h-6 bg-[#f8f8f8];
            }

            .weekend {
                @apply inline-block w-6 h-6 bg-[#eeeeee];
            }

            .booked {
                @apply inline-block w-6 h-6 bg-red-300;
            }

            .holiday {
                @apply inline-block w-6 h-6 bg-orange-300;
            }
        }

        [id^=calendar] {
            @apply min-w-[960px] md:w-full p-[10px] border border-[#E0E0E0] flex bg-white relative;

            ul{
                @apply inline-block p-0 my-0 mx-[1px] list-none flex-[1] relative;

                &:before{
                    @apply absolute content-[attr(data-label)] whitespace-nowrap text-accent-1 font-['Italiana']
                    font-bold text-4xl tracking-wide rotate-90 translate-x-0 translate-y-[-35px] top-0 left-0
                    origin-bottom-left drop-shadow-[2px_0px_0px_rgba(248,248,248,1)];
                }

                &:first-child{
                    @apply ml-0;
                }
                &:last-child{
                    @apply mr-0;
                }

                li{
                    @apply p-0 m-0 h-[22px] shadow-inner border border-white bg-[#f8f8f8];

                    &:before{
                        @apply content-[attr(data-date)] text-right right-[4px] absolute leading-[22px] text-[10px]
                        text-accent-3;
                    }


                    &[data-day] {
                        &:after {
                            @apply relative w-full h-[22px] content-[attr(data-label)] flex items-center justify-center
                            uppercase text-slate-300 text-xs;
                        }
                    }

                    &[data-day="0"], &[data-day="6"]{
                        @apply bg-[#eeeeee];
                    }

                    &[data-type="2"] {
                        &:after {
                            @apply text-orange-300;
                        }
                    }

                    &[data-type="0"] {
                        @apply bg-red-300;

                        &:after {
                            @apply text-red-500;
                        }
                    }
                }
            }
        }
    }

    /* Bugfix for user with Loom extension */

    .loom-companion-mv3 {
        @apply hidden;
    }

    @mixin keyframe-origin($value) {
        -ms-transform: $value;
        -webkit-transform: $value;
        -o-transform: $value;
        -moz-transform: $value;
    }

    @mixin keyframe-transform($value) {
        -ms-transform: $value;
        -webkit-transform: $value;
        -o-transform: $value;
        -moz-transform: $value;
    }

    @keyframes move {
        0% {
            @apply origin-bottom-left scale-[1.00];
            @include keyframe-origin(bottom left);
            @include keyframe-transform(scale(1.0));
        }
        50% {
            @apply scale-[1.2];
            @include keyframe-transform(scale(1.2));
        }
        100% {
            @apply scale-[1.00];
            @include keyframe-transform(scale(1.0));
        }
    }

    @keyframes slide-move {
        0% {
            @apply origin-center translate-x-[30vw] scale-[2.4];
            @include keyframe-origin(center);
            @include keyframe-transform(translateX(30vw) scale(2.4));
        }
        50% {
            @apply origin-center translate-x-[-30vw] scale-[2.4];
            @include keyframe-origin(center);
            @include keyframe-transform(translateX(-30vw) scale(2.4));
        }
        100% {
            @apply origin-center translate-x-[30vw] scale-[2.4];
            @include keyframe-origin(center);
            @include keyframe-transform(translateX(30vw) scale(2.4));
        }
    }

    @keyframes zoom-out {
        0% {
            @apply -translate-x-1/2 -translate-y-1/2 scale-[1.0] opacity-100;
            @include keyframe-transform(translate(-50%, -50%) scale(1.0));
        }
        100% {
            @apply -translate-x-1/2 -translate-y-1/2 scale-[0.9] opacity-0;
            @include keyframe-transform(translate(-50%, -50%) scale(0.9));
        }
    }

    @keyframes fade-out {
        0% {
            @apply opacity-100;
        }
        40% {
            @apply opacity-100;
        }
        100% {
            @apply opacity-0;
        }
    }
}
